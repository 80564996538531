<template>
    <div class="p-1">
        <b-row>
            <b-col
                cols="12"
                md="8"
            >
                <b-tabs>
                    <b-tab
                        active
                        :title="i18nT(`Job offer`)"
                    >
                        <template #title>
                            <div class="text-left">
                                <feather-icon icon="FileTextIcon"/>
                                <span>{{ i18nT(`Job offer`) }}</span>
                            </div>
                        </template>
                        <b-card no-body>
                            <b-card-body>
                                <h4 class="mb-2">{{offer.Label}}</h4>

                                <b-row class="border-bottom p-1">
                                    <b-col cols="3">
                                        <h5 class="text-muted">{{i18nT(`Job Category`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9">
                                        <h5>{{ getNameByCategoryId(offer.Category) }}</h5>
                                    </b-col>
                                    <b-col cols="3">
                                        <h5 class="text-muted">{{i18nT(`Department`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9">
                                        <h5>{{ getNameByDepartmentId(offer.Department) }}</h5>
                                    </b-col>
                                    <b-col cols="3">
                                        <h5 class="text-muted">{{i18nT(`Contract Type`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9">
                                        <h5>{{ getNameByContractTypeId(offer.ContractType) }}</h5>
                                    </b-col>
                                    <b-col cols="3">
                                        <h5 class="text-muted">{{i18nT(`Description`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9">
                                        <p v-html="offer.Description"/>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom p-1">
                                    <b-col v-if="offer.Required" cols="3">
                                        <h5 class="text-muted">{{i18nT(`Requirements`)}}</h5>
                                    </b-col>
                                    <b-col v-if="offer.Required" cols="9">
                                        <h5>{{ offer.Required }}</h5>
                                    </b-col>
                                    <b-col cols="3">
                                        <h5 class="text-muted">{{i18nT(`Seniority Level`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9">
                                        <h5>{{ getNameSeniorityLevelId(offer.SeniorityLevel) }}</h5>
                                    </b-col>
                                </b-row>

                                <b-row v-if="offer.Benefits" class="border-bottom p-1">
                                    <b-col cols="3">
                                        <h5 class="text-muted">{{i18nT(`Benefits`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9">
                                        <h5>{{ offer.Benefits }}</h5>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom p-1" v-if="offer.Additional">
                                    <b-col cols="3">
                                        <h5 class="text-muted">{{i18nT(`Additional Information`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9">
                                        <p v-html="offer.Additional"/>
                                    </b-col>
                                </b-row>

                                <b-row class="border-bottom p-1">
                                    <b-col cols="3">
                                        <h5 class="text-muted">{{i18nT(`Salary`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9">
                                        <h5>{{ offer.SalaryFrom }}{{ offer.Currency }} - {{ offer.SalaryTo }}{{ offer.Currency }}</h5>
                                    </b-col>
                                    <b-col cols="3" v-if="offer.EquityFrom || offer.EquityTo">
                                        <h5 class="text-muted">{{i18nT(`Equity`)}}:</h5>
                                    </b-col>
                                    <b-col cols="9" v-if="offer.EquityFrom || offer.EquityTo">
                                        <h5>{{ offer.EquityFrom }} - {{ offer.EquityTo }}</h5>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab
                        :title="i18nT(`My Application`)"
                    >

                        <template #title>
                            <div class="text-left">
                                <feather-icon icon="FileTextIcon"/>
                                <span>{{ i18nT(`My Application`) }}</span>
                            </div>
                        </template>
                            <!-- CV -->
                        <b-card no-body>
                            <b-card-body>
                                <!-- DOCUMENTS -->
                                <b-row class="mb-1">
                                    <b-col>
                                        <h4 class="mb-2">{{i18nT(`Submitted documents`)}}</h4>
                                        <div v-if="currentApplication && currentApplication.CvObj" class="tab-section">
                                            <h6 class="section-label">{{ i18nT(`CV`) }}</h6>
                                            <div class="detail-field">
                                                <div>
                                                    <b-link :href="currentApplication.CvObj.DownloadUrl">
                                                        {{ currentApplication.CvObj.Label }}
                                                    </b-link>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- COVER LETTER -->
                                        <div v-if="currentApplication && currentApplication.CoverLetterObj" class="tab-section">
                                            <hr class="invoice-spacing"/>
                                            <h6 class="section-label">{{ i18nT(`Motivation letter`) }}</h6>
                                            <div class="detail-field">
                                                <div>
                                                    <b-link
                                                        :href="currentApplication.CoverLetterObj.DownloadUrl">
                                                        {{ currentApplication.CoverLetterObj.Label }}
                                                    </b-link>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="!currentApplication.CvObj && !currentApplication.CoverLetterObj" class="text-left">
                                            <p class="font-italic">{{i18nT(`No additional documents required for this offer.`)}}</p>
                                        </div>
                                        <hr/>
                                    </b-col>
                                </b-row>

                                <!-- SOCIAL LINKS -->
                                <b-row class="mb-1">
                                    <b-col>
                                        <h4 class="mb-1">{{i18nT(`Social Links`)}}</h4>
                                        <div v-if="currentApplication && hasSocialLinks">
                                            <div v-for="(socialName, index) in availableSocialLinks"
                                                 :key="'social-'+index"
                                                 class="pb-1"
                                            >
                                                <h6 v-if="currentApplication[socialName]">{{ socialName }}</h6>
                                                <b-link v-if="currentApplication[socialName]" :href="currentApplication[socialName]">{{currentApplication[socialName]}}</b-link>
                                            </div>
                                        </div>
                                        <div v-else class="text-left">
                                            <p class="font-italic">{{i18nT(`No social links required for this offer.`)}}</p>
                                        </div>
                                        <hr/>
                                    </b-col>
                                </b-row>

                                <!-- ANSWERS -->
                                <b-row>
                                    <b-col>
                                        <h4 class="mb-1">{{i18nT(`My answers to the application questions`)}}</h4>        <!-- Question ANSWERS -->
                                        <div v-if="currentApplication && currentApplication.Answers && currentApplication.Answers.length>0">
                                            <div v-for="(oneAnswer, index) in currentApplication.Answers" :key="index" class="pt-1">
                                                <h6>{{ index + 1 }}. {{ oneAnswer.Question }}</h6>
                                                <p>{{ oneAnswer.Label }}</p>
                                            </div>
                                        </div>
                                        <div v-else class="text-left">
                                            <p class="font-italic">{{i18nT(`No additional questions required for this offer.`)}}</p>
                                        </div>
                                    </b-col>
                                </b-row>

                        </b-card-body>
                    </b-card>

                    </b-tab>
                    <b-tab :title="i18nT(`Interviews`)" >
                        <template #title>
                            <feather-icon icon="CalendarIcon"/>
                            <span>{{ i18nT(`Interviews`) }}</span>
                        </template>

                        <b-card no-body>
                            <b-card-body>
                                <h4 class="mb-2">{{i18nT(`Interviews`)}}</h4>
                                <interviews
                                    v-if="interviews.length >0"
                                    :data="interviews"
                                    :show-delete="false"
                                    class="mb-2"
                                />

                                <div v-else class="text-left">
                                    <p class="font-italic">{{i18nT(`No interviews scheduled yet for this job application.`)}}</p>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                </b-tabs>

            </b-col>

            <b-col
                cols="12"
                md="4"
                class="pt-4"
            >
                <b-card no-body>
                    <b-card-body>
                        <b-row>
                            <b-col>
                                <h4 class="mb-1">{{i18nT(`Key information`)}}</h4>
                                <div
                                    class="p-1 bg-light"
                                >
                                    <ul class="text-dark p-0 list-style-none">
                                        <li>{{i18nT(`Offer ID`)}}: <strong>{{offer.Id}}</strong></li>
                                        <li>{{i18nT(`Applied on`)}}: <strong>{{currentApplication.CreatedAt | date}}</strong></li>
                                        <li>{{i18nT(`Contract Type`)}}: <strong>{{getNameByContractTypeId(offer.ContractType)}}</strong></li>
                                        <li>{{i18nT(`Region/City`)}}: <strong>{{offer.City}}</strong></li>
                                        <li>{{i18nT(`Country`)}}: <strong>{{getNameByCountryId(offer.Country)}}</strong></li>
                                        <li>{{i18nT(`Remote Work`)}}: <strong>{{offer.Remote ? i18nT(`Yes`) : i18nT(`No`)}}</strong></li>
                                        <li>{{i18nT(`Visa Sponsorship`)}}: <strong>{{offer.SponsorVisa ? i18nT(`Yes`) : i18nT(`No`)}}</strong></li>
                                    </ul>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12"  v-if="offer.PublicationStatus == 'ACTIVE'" class="pt-2">
                                <h6 class="section-label">{{i18nT(`Job offer url`)}}</h6>
                                <div
                                    @click="copyUrl"
                                    class="mb-1"
                                >
                                    <b-input-group>
                                        <b-input-group-prepend is-text>
                                            <feather-icon icon="LinkIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input
                                            v-model="publicUrl"
                                            disabled
                                        />
                                    </b-input-group>
                                </div>
                                <p class="text-muted mb-0">{{i18nT(`You can review or share the original job posting using this link.`)}}</p>
                                <b-button
                                    variant="primary"
                                    class="mt-1 mb-1"
                                    :href="publicUrl"
                                    target="_blank"
                                >{{i18nT(`Review`)}}</b-button>
                            </b-col>

                            <b-col cols="12"  v-else>
                                <h6 class="section-label">{{i18nT(`Job offer url`)}}</h6>
                                <div class="mb-1">
                                    <p class="font-italic">{{i18nT(`The job offer is no longer published.`)}}</p>
                                </div>
                            </b-col>
                        </b-row>


                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    BFormInput, BButton, BLink, BInputGroupPrepend, BInputGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useAuth from '@/auth/useAuth'
import { dictToSelectArray } from '@core/utils/utils'
import Interviews from "@/views/recruiting/Candidates/Interviews.vue";

export default {
    components: {
        BInputGroup, BInputGroupPrepend,
        BLink,
        BButton, Interviews,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTabs,
        BTab,
        BFormInput
    },
    directives: {
        Ripple
    },
    data() {
        return {
            required,
            currentApplication: {},
            interviews: [],

            socialLinkList: [
                'Twitter',
                'Instagram',
                'Behance',
                'Dribble',
                'Github',
                'Youtube'
            ],

            offer: {},
            offerCategories: [],
            offerContractTypes: [],
            offerSeniorityLevels: [],
            offerDepartments: [],
            offerJobSkills: [],
            offerCountries: [],
            offerCategory: {},
            offerDepartment: [],
            offerContractType: {},
            offerSeniorityLevel: {},
            offerRequiredSkills: [],
            offerId: '',
            offerLabel: '',
            offerDescription: '',
            offerAddress: '',
            offerCity: '',
            offerCountry: '',
            offerSalaryFrom: '',
            offerSalaryTo: '',

            offerSalaryConfidential: false,
            offerSponsorVisa: false,
            offerRemote: false,
            offerBenefits: '',
            offerAdditional: '',

            questionTypes: [],
            question: null,
            questionType: { value: '1' },
            questionLabel: '',
            questionDescription: '',
            answersData: [''],
            linesCnt: 1,


            jobOfferId: '',
            publicUrl: ''
        }
    },
    computed: {
        hasSocialLinks() {
            let hasLinks = false;
            for(let i=0; i<this.socialLinkList.length; i++){
                if(this.currentApplication[this.socialLinkList[i]]){
                    hasLinks = true;
                    break;
                }
            }
            return hasLinks;
        },
        availableSocialLinks(){
            let availableLinks = [];
            for(let i=0; i<this.socialLinkList.length; i++){
                if(this.currentApplication[this.socialLinkList[i]]){
                    availableLinks.push(this.socialLinkList[i]);
                }
            }
            return availableLinks;
        },
    },
    watch: {
        file: function(val) {
            if (val) {
                this.file = val
                this.fileName = val.name
            } else {
                this.file = null
                this.fileName = ''
            }
        }
    },
    async created() {
        this.getOfferCategories()
        this.getOfferContractTypes()
        this.getOfferSeniorityLevels()
        this.getOfferDepartments()
        this.getOfferJobSkills()
        this.getOfferCountries()

        this.jobOfferId = this.$route.params.id
        let applicationId = this.$route.params.id

        this.$http.get('candidates/applications?id=' + applicationId).then(({data})=> {
            this.currentApplication = data.data;
            this.offer = data.data.OfferObj;
            this.answers = data.data.Answers;
            this.interviews = data.data.Interviews;
            this.publicUrl = `${window.location.protocol}//${window.location.host}/apply/${this.offer.Label.split(' ').join('-')}_${this.offer.Id}`

            console.log("Application object", this.currentApplication);
        })

    },
    methods: {
        copyUrl() {
            navigator.clipboard.writeText(this.publicUrl)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: this.i18nT(`Url copied`),
                    variant: 'primary'
                }
            })
        },
        getOfferCategories() {
            this.$http.get(`system/jobCategories`).then(({ data }) => {
                this.offerCategories = dictToSelectArray(data.data)
            })
        },
        getOfferContractTypes() {
            this.$http.get(`system/contractTypes`).then(({ data }) => {
                this.offerContractTypes = dictToSelectArray(data.data)
            })
        },
        getOfferSeniorityLevels() {
            this.$http.get(`system/seniorityLevels`).then(({ data }) => {
                this.offerSeniorityLevels = dictToSelectArray(data.data)
            })
        },
        getOfferDepartments() {
            this.$http.get(`system/departments`).then(({ data }) => {
                this.offerDepartments = dictToSelectArray(data.data)
            })
        },
        getOfferJobSkills() {
            this.$http.get(`offers/jobSkills?type=2`).then(({ data }) => {
                this.offerJobSkills = data.data.map(d => ({
                    value: d.id,
                    text: d.label
                }))
            })
        },
        getOfferCountries() {
            this.$http.get(`system/countries`).then(({ data }) => {
                this.offerCountries = dictToSelectArray(data.data)
            })
        },
        getNameByCategoryId(categoryId) {
            const category = this.offerCategories.find(cat => cat.value == categoryId)
            return category ? category.text : ''
        },
        getNameByDepartmentId(departmentId) {
            const department = this.offerDepartments.find(
                dep => dep.value == departmentId
            )
            return department ? department.text : ''
        },
        getNameByContractTypeId(contractTypeId) {
            const contractType = this.offerContractTypes.find(
                con => con.value == contractTypeId
            )
            return contractType ? contractType.text : ''
        },
        getNameByCountryId(countryId) {
            const country = this.offerCountries.find(con => con.value == countryId)
            return country ? country.text : ''
        },
        getNameSeniorityLevelId(levelId) {
            const level = this.offerSeniorityLevels.find(lev => lev.value == levelId)
            return level ? level.text : ''
        },


    }
}
</script>

<style lang="scss">
.section-title {
    font-size: 11px;
}
.document-name {
    display: inline;
    line-height: 32px;
}
.document-actions-bar {
    float: right;
}
</style>
